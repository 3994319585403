<template>
  <div id="class-list">
    <!-- app drawer -->
    <class-list-add-new
      v-model="isAddNewClassSidebarActive"
      @refetch-data="fetchClasses"
    ></class-list-add-new>

    <!-- class total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in classTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveClassTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveClassTotalIcon(total.title).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveClassTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveClassTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- table -->
    <v-card>
      <v-card-title>
        Klassen
      </v-card-title>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Zoeken"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewClassSidebarActive = !isAddNewClassSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Klas toevoegen</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="classListTable"
        :options.sync="options"
        :server-items-length="totalClassListTable"
        :loading="loading"
        loading-text="Klassen aan het laden..."
        no-data-text="Geen klassen gevonden"
      >
        <!-- Class name -->
        <template #[`item.name`]="{item}">
          <router-link
            :to="{ name : 'users-class-view', params : { id : item.id } }"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            <span><b>{{ item.name }}</b></span>
          </router-link>
        </template>

        <!-- Amount of pupils -->
        <template #[`item.pupils`]="{item}">
          <span>{{ item.pupils.length }}</span>
        </template>

        <!-- Amount of teachers -->
        <template #[`item.teachers`]="{item}">
          <span>{{ item.teachers.length }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'users-class-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="onBtnDeleteClassClick(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Verwijderen</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <!-- Delete dialog -->
    <v-dialog
      v-if="deleteClass.showDeleteDialog"
      v-model="deleteClass.showDeleteDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Klas verwijderen
        </v-card-title>

        <v-card-text>
          Ben je zeker dat je {{ deleteClass.classToDelete.name }} wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            @click="deleteClass.showDeleteDialog = false"
          >
            Annuleren
          </v-btn>
          <v-btn
            color="error"
            @click="onBtnDeleteClassConfirm"
          >
            Verwijderen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiFileDocumentOutline, mdiPlus, mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'
import classStoreModule from '../classStoreModule'
import ClassListAddNew from './ClassListAddNew.vue'

import useClassesList from './useClassesList'

function toast(status, title, text, duration = 3000) {
  Swal.fire({
    position: 'top-end',
    icon: status,
    title,
    text,
    showConfirmButton: false,
    timer: duration,
    toast: true,
  })
}

export default {
  components: {
    ClassListAddNew,
  },
  setup() {
    const CLASS_APP_STORE_MODULE_NAME = 'app-class'
    const isDialogVisible = ref(false)

    console.log('Going to load classes list')
    const qrTest = ref({
      value: 'https://example.com',
      size: 300,
    })

    // Register module
    if (!store.hasModule(CLASS_APP_STORE_MODULE_NAME)) store.registerModule(CLASS_APP_STORE_MODULE_NAME, classStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLASS_APP_STORE_MODULE_NAME)) store.unregisterModule(CLASS_APP_STORE_MODULE_NAME)
    })
    const {
      classListTable,
      tableColumns,
      searchQuery,
      planFilter,
      statusFilter,
      totalClassListTable,
      loading,
      options,
      classTotalLocal,
      selectedRows,

      fetchClasses,
    } = useClassesList()

    const isAddNewClassSidebarActive = ref(false)

    // Leerling verwijderen
    const deleteClass = ref({
      showDeleteDialog: false,
      classToDelete: {},
    })

    function onBtnDeleteClassClick(classObj) {
      deleteClass.value.showDeleteDialog = true
      deleteClass.value.classToDelete = classObj
    }

    function onBtnDeleteClassConfirm() {
      console.log(deleteClass.value.classToDelete.id)
      store.dispatch('app-class/deleteClass', deleteClass.value.classToDelete).then(() => {
        deleteClass.value.showDeleteDialog = false
        toast('success', 'Klas verwijderd', `Klas ${deleteClass.value.classToDelete.name} is verwijderd`)
        fetchClasses()
      })
    }

    return {
      qrTest,
      deleteClass,
      onBtnDeleteClassClick,
      onBtnDeleteClassConfirm,
      isDialogVisible,
      classListTable,
      tableColumns,
      searchQuery,
      planFilter,
      statusFilter,
      totalClassListTable,
      loading,
      options,
      classTotalLocal,
      isAddNewClassSidebarActive,
      selectedRows,
      avatarText,
      fetchClasses,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
